<template>
   <div class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>会员标签</el-breadcrumb-item>
            <el-breadcrumb-item>标签库</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 主体内容 -->
      <el-row class="content-box">
         <!-- 表头 -->
         <el-row class="table-box">
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary" @click="addEditAuto('add')">新增标签</el-button>
               </el-row>
            </div>
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  style="width: 100%"
                  :stripe="true"
                  border fit>
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column label="标签名" min-width="50">
                  <template slot-scope="scope">
                     <el-tag effect="dark" :color="scope.row.bgcolor" >{{scope.row.tagName}}</el-tag>
                  </template>
               </el-table-column>
               <el-table-column prop="bgcolor" label="颜色" min-width="70"></el-table-column>
               <el-table-column label="类型" min-width="70">
                  <template slot-scope="scope">{{ scope.row.type | filterType(that) }}</template>
               </el-table-column>
               <el-table-column prop="createTime" label="创建时间" min-width="80"></el-table-column>
               <el-table-column prop="remark" label="备注" min-width="140"></el-table-column>
               <el-table-column :label="$t('msg.operating')" fixed="right" min-width="70">
                  <template slot-scope="scope">
                     <el-button type="text" @click="addEditAuto('look', scope.row)" >
                        <el-link type="primary">查看</el-link>
                     </el-button>
                     <el-button type="text" @click.stop="addEditAuto('edit', scope.row)">
                        <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button type="text" style="color: #F56C6C;" @click.stop="handleDel(scope.row.id)">
                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </el-row>
      </el-row>
   </div>
</template>
<script>
import { urlObj } from '@/api/interface'
import { mapState } from 'vuex'
import { label } from "@/api/interface/data";
import { getDict } from "@/common/js/common";
export default {
   data(){
      return{
         that: this,
         loading: false,
         tableData: [],
         rules: {
            tagName: [{ required: true, message: '请输入标签名', trigger: 'blur' }]
         },
         id: '',
         action: 'add',
         del_success: '删除成功！',
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',//
         prompt: '提示！',
      }
   },

   mounted() {
      getDict(['tag-type'])
      this.getTagLibrary()
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData'])
   },
   methods: {
      // 获取标签库列表
      getTagLibrary(){
         const url = label.tagLibrary
         const param = { companyId: this.hotelInfo.storeId }
         this.$axios.post(url, param).then(res => {
            res.success && (this.loading = false, this.tableData = res.records)
         })
      },
      // 添加/编辑标签
      addEditAuto(action, row){
         if (action === 'edit' || action === 'look') sessionStorage.setItem('tagInfo', JSON.stringify(row))
         this.$router.push({ path: '/add_auto_tag', query: { action } })
      },
      // 删除标签库
      handleDel(id){
         this.$confirm(this.confirm_remove, this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning'
         }).then(() => {
            const param = { id }
            const url = label.delTagLibrary
            this.$axios.post(url,param).then(res => {
               if (res.code === 'SUCCESS'){
                  this.$message({
                     showClose: true,
                     message: this.del_success,
                     type: 'success'
                  })
                  this.getTagLibrary()
               }
            })
         })
      },
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         oldVal && newVal.id !== oldVal.id && this.getTagLibrary()
      }
   },
   filters: {
      filterType(val, that){
         if (val && that.dictData['tag-type']) return that.dictData['tag-type'][val] || val
      }
   }
}
</script>

<style scoped lang="scss">
   .el-tag{ border-color: transparent }
</style>
